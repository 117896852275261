<script>
import LocaleSelect from './LocaleSelect'

import AuthApiService from '../../services/domains/Auth'

import {mapGetters, mapActions} from 'vuex'
import sidebarMenu from '../../menu'

const authService = AuthApiService.build({})

export default {
  name: 'app-sidebar',
  components: {LocaleSelect},
  props: ['customLayout'],
  data() {
    return {
      clientWidth: document.documentElement.clientWidth,
      showContactModal: false
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'showSidebar']),
    sidebarMenu() {
      return sidebarMenu
    },
    drawer: {
      get: function () {
        return this.showSidebar
      },
      set: function (value) {
        this.toggleSidebar(value)
      }
    }
  },
  async mounted() {
    this.checkScreenSize()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions(['toggleSidebar', 'logout', 'setTenant']),
    async handleResize() {
      this.clientWidth = await document.documentElement.clientWidth
    },
    checkScreenSize() {
      if (this.clientWidth < 1264) {
        this.toggleSidebar(false)
      }
    },
    onLogout() {
      authService.logout().then(() => {
        this.logout().then(() => {
          this.$router.push({name: 'home'})
        })
      })
    },
    onContact() {
      this.showContactModal = !this.showContactModal
    },
    checkSectionVisibility(section) {
      if (!section.text()) {
        return false
      }
      return (section.auth === true && this.isLogged) || !section.auth
    },
    checkMenuVisibility(menu) {
      if (menu.hideLogged && this.isLogged) {
        return false
      }
      return (menu.auth === true && this.isLogged) || !menu.auth
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<template>
  <v-navigation-drawer v-model="drawer"
                       app
                       dark
                       clipped
                       :hide-overlay="true"
                       :touchless="false"
                       :width="210">
    <div class="navigation-drawer-box fill-height">
      <div>
        <v-list dense class="navigation-drawer-sidebar">
          <template v-for="(menu, menuIndex) in sidebarMenu">
            <v-subheader class="mt-3 grey--text text--darken-1 text-uppercase"
                         :key="menuIndex"
                         v-if="checkSectionVisibility(menu)">
              {{ menu.text() }}
            </v-subheader>
            <template v-for="(item, itemIndex) in menu.children">
              <v-list-item :to="{name: item.route}"
                           exact
                           v-if="checkMenuVisibility(item)"
                           :key="`${item.route}_${itemIndex}`">
                <v-list-item-action>
                  <div class="sidebar-icon"><i :class="item.icon"></i></div>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text() }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
          <v-list-item @click="onContact" key="menuContact">
            <v-list-item-action>
              <div class="sidebar-icon"><i class="fas fa-envelope"></i></div>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('components.main.sidebar.menu.items.contact') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="isLogged"></v-divider>

        <v-list dense class="navigation-drawer-sidebar">
          <v-list-item @click="onLogout" key="userLogout" v-if="isLogged">
            <v-list-item-action>
              <div class="sidebar-icon"><i class="fas fa-sign-out-alt"></i></div>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('components.main.sidebar.menu.items.userLogout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </div>

      <locale-select :sidebar="true"/>

    </div>

    <v-dialog v-model="showContactModal" max-width="350">
      <v-card>
        <v-card-text class="pt-5">
          <div class="home-contacts">
            <h3 class="sub-heading text-center">
              {{ $t('app.domains.home.components.contactCardTitle') }}
            </h3>
            <h4 :class="['pb-2 text-center', (!$vuetify.breakpoint.mobile ? 'body-1' : 'body-2')]">
              {{ $t('app.domains.home.components.contactCardText') }}
            </h4>
            <div class="pt-2">
              <div class="home-contacts-item -email">
                <i class="fas fa-envelope"></i>
                <span class="ml-2"><a href="mailto:contato@gripo.com.br">contato@gripo.com.br</a></span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-navigation-drawer>
</template>

<style lang="scss">
.navigation-drawer-box {
  width: 100%;

  > div {
    width: 100%;
  }
}

.navigation-drawer-sidebar {
  > .v-subheader {
    height: 30px;
    margin: 0 !important;
  }

  .v-list-item__action {
    margin: 8px 10px 8px 0 !important;
  }
}

.sidebar-icon {
  margin: 0 auto;
}
</style>
