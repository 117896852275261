import store from '../vuex'
import bus from '@utils/bus'
import { set, get } from '@utils/storage'

import i18n from '../translation'

const setUpAuth = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        // Get user data
        const token = await get('token')
        const user = await get('user')
        const permissions = await get('permissions')
        const city = await get('city')
        const club = await get('club')
        const tenantId = await get('tenant_id')

        // Store in vuex user data
        const data = {
            token: token,
            user: user,
            permissions: permissions,
            city: city,
            club: club,
            tenant_id: tenantId
        }

        const country = user?.country || {code: 'BRA', locale: 'pt_BR'}
        i18n.locale = country.locale

        await store.dispatch('setUpAuth', data)
        resolve()
    })
}

export default async (to, from, next) => {
    const stageRoutes = ['registration.store', 'stage.show.pairs', 'stage.show.groups', 'stage.show.matches'];
    if (to.name && stageRoutes.includes(to.name)) {
        await set('current-scroll-position', document.documentElement.scrollTop || document.body.scrollTop)
    }
    if (!from.name && to.name === 'home') {
        await set('current-scroll-position', 0)
    }
    // Position the scroll on top
    window.scrollTo(0, 0)

    // Show global loader
    bus.$emit('show-loader')

    if (to.name === 'auth.login') {
        await store.dispatch('logout')
    }

    // Get auth data from storage and setUpAuth
    const token = await get('token')
    if (token) {
        await setUpAuth()
    }

    // Check if route doesn't need auth
    if (to.meta.auth === false || !to.name) {
        return next()
    }

    // Go to dashboard if is logged
    if ((to.name === 'home' || to.name === 'auth.register' || to.name === 'auth.login') && token) {
        bus.$emit('hide-loader')
        return next({name: 'dashboard'})
    }

    // If token doesn't exists, redirect to auth
    if (!token) {
        if (!window.hasOwnProperty('gripo')) {
            window.gripo = {}
        }
        window.gripo.redirect_route = {name: to.name, params: to.params}
        setTimeout(() => {
            window.location.href = `${process.env.VUE_APP_APP_URL}/login`
        }, 100)
        return false
    }

    return next()
}
