<script>
import EmptyList from '../../../components/general/EmptyList';
import LoadingContent from "../../../components/general/placeholders/LoadingContent";
import TransactionPaymentModal from "../../../components/general/TransactionPaymentModal"

import OrderService from '../../../services/domains/Order';

import bus from '@utils/bus';
import { sortBy } from 'lodash';
import moment from 'moment';
import * as notifyService from '@/app/services/common/notify';

const orderService = OrderService.build({});

export default {
  components: {LoadingContent, EmptyList, TransactionPaymentModal},
  data() {
    return {
      isLoadingData: true,
      filters: {
        club_id: '',
        dates: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },
      dependencies: {
        clubs: [],
        cities: [],
      },
      showModalDatePicker: false,
      data: {
        headers: [
          {
            text: this.$t('app.domains.order.components.list.table.headers.due_date'),
            value: 'due_date'
          },
          {
            text: this.$t('app.domains.order.components.list.table.headers.description'),
            value: 'description'
          },
          {
            text: this.$t('app.domains.order.components.list.table.headers.amount'),
            value: 'amount',
            align: 'right'
          },
          {
            text: '',
          }
        ],
        list: []
      },
      transaction: null,
      showPixModal: false,
      showProductsModal: false,
      products: []
    };
  },
  computed: {
    filtersDates: {
      get() {
        if (this.filters.dates.length > 0) {
          if (this.filters.dates.length === 1) {
            return moment(this.filters.dates[0]).format('DD/MM/YYYY');
          } else {
            return 'De ' + moment(this.filters.dates[0]).format('DD/MM/YYYY') + ' até ' + 'De ' + moment(this.filters.dates[1]).format('DD/MM/YYYY');
          }
        }
        return '';
      },
      set(value) {
        if (!value) {
          this.filters.dates.splice(0);
        } else {
          this.filters.dates = [...value];
        }
      }
    }
  },
  async mounted() {
    await this.getClubs();
    this.loadOrders();
  },
  methods: {
    getClubs() {
      return new Promise(resolve => {
        this.isLoadingCities = true;
        orderService.clubs().then(response => {
          // TODO: Ajustar ordenação no back (Está sendo feito aqui pq por algum motivo bizarro a ordenação do back não ta funcionando)
          this.dependencies.clubs = [...sortBy(response, ['city', 'name'], ['desc', 'asc'])];
          resolve();
        });
      });
    },
    prepareSearchData() {
      const search = {};
      if (this.filters.club_id) {
        search.club_id = this.filters.club_id;
      }
      if (this.filters.dates.length > 0) {
        if (this.filters.dates.length === 1) {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
          search.end_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
        } else {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
          search.end_date = moment(this.filters.dates[1]).format('YYYY-MM-DD');
        }
      }
      return search;
    },
    loadOrders() {
      this.isLoadingData = true;
      this.data.list.splice(0);
      const data = this.prepareSearchData();
      if (!data.club_id) {
        this.isLoadingData = false;
        bus.$emit('hide-loader');
        return;
      }
      orderService.my(data)
          .then(response => {
            this.data.list = [...response];
            this.isLoadingData = false;
            bus.$emit('hide-loader');
          })
          .catch(e => {
            this.isLoadingData = false;
            bus.$emit('hide-loader');
            console.log(e);
          });
    },
    handlePaymentModal(item) {
      if (item.transaction && !item.transaction.id_paid) {
        this.showPaymentModal(item.transaction)
      } else {
        this.generateTransaction(item)
        this.loadOrders()
      }
    },
    async generateTransaction(item) {
      const confirm = await notifyService.confirm({title: this.$t('app.domains.order.components.list.generateTransactionMessage')});
      if (confirm) {
        bus.$emit('show-loader');
        this.isLoadingData = true;
        const data = {
          club_id: item.club_id,
          portion_id: item.id,
        };
        orderService.generateTransaction(data)
            .then((response) => {
              this.showPaymentModal(response.transaction);
              bus.$emit('hide-loader');
              this.isLoadingData = false;
            })
            .catch(e => {
              this.isLoadingData = false;
              bus.$emit('hide-loader');
              console.log(e);
            });
      }
    },
    showPaymentModal(transaction) {
      this.transaction = transaction
      this.showPixModal = true
    },
    showDetails(portion) {
      this.products = [...portion.products]
      this.showProductsModal = true
    }
  }
};
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.order.components.list.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex md2 xs12>
            <v-select :items="dependencies.clubs"
                      v-model="filters.club_id"
                      :label="$t('app.domains.order.components.list.filters.clubLabel')"
                      :placeholder="$t('app.domains.order.components.list.filters.clubLabel')"
                      :no-data-text="$t('app.domains.order.components.list.emptyListMessage')"
                      item-text="name"
                      item-value="id"
                      @change="loadOrders"
                      clearable
                      hide-details/>
          </v-flex>
          <v-flex md4 xs12>
            <v-menu ref="filtersStartDate"
                    :close-on-content-click="false"
                    v-model="showModalDatePicker"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :label="$t('app.domains.registration.components.list.filters.dateLabel')"
                    :placeholder="$t('app.domains.registration.components.list.filters.dateLabel')"
                    readonly
                    v-model="filtersDates"
                    class="v-field--hide-details"
                    clearable
                    v-on="on">
                  <template v-slot:prepend>
                    <i class="fas fa-calendar-alt"></i>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.dates"
                             range
                             no-title
                             color="primary"
                             scrollable
                             :selected-items-text="filtersDates"
                             locale="pt-br"/>
            </v-menu>
          </v-flex>
          <v-flex md1 xs12>
            <v-btn color="secondary" class="mt-2"
                   :title="$t('app.domains.order.components.list.filters.filterBtn')"
                   @click="loadOrders">
              <i class="fas fa-filter"></i>
              <span>{{ $t('app.domains.order.components.list.filters.filterBtn') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-data-table :headers="data.headers"
                    :items="data.list"
                    v-if="!isLoadingData && data.list.length > 0"
                    :no-data-text="$t('app.texts.listEmptyMessage')"
                    disable-sort
                    hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.due_date | dateEnToBr }}</td>
            <td><span v-html="item.description"></span></td>
            <td style="min-width: 110px;" class="text-right">{{ item.amount | currencyEnToBr }}</td>
            <td>
              <v-btn color="info"
                     :class="[$vuetify.breakpoint.xs ? 'mb-1' : 'mr-1']"
                     :title="$t('app.domains.order.components.list.table.items.actions.showBtn')"
                     small
                     @click="showDetails(item)">
                <i class="fas fa-search"></i>
              </v-btn>
              <v-btn color="success"
                     :title="$t('app.domains.order.components.list.table.items.actions.payBtn')"
                     small
                     @click="handlePaymentModal(item)">
                <i class="fas fa-dollar-sign"></i>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <transaction-payment-modal :transaction="transaction"
                                 :show.sync="showPixModal"/>
      <v-dialog v-model="showProductsModal" max-width="600">
        <v-card>
          <v-card-title class="text-center">{{ $t('app.domains.order.components.productsTable.title') }}</v-card-title>
          <v-card-text class="pt-3">
            <table class="stage-groups-table" v-if="products.length">
              <thead>
              <tr>
                <th class="text-left">{{
                    $t('app.domains.order.components.productsTable.table.headers.description')
                  }}
                </th>
                <th class="text-center">{{
                    $t('app.domains.order.components.productsTable.table.headers.quantity')
                  }}
                </th>
                <th class="text-right">{{ $t('app.domains.order.components.productsTable.table.headers.price') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in products" :key="index">
                <td class="text-left">{{ product.description }}</td>
                <td class="text-center">{{ product.quantity }}</td>
                <td class="text-right">{{ product.price | currencyEnToBr }}</td>
              </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <empty-list v-if="!isLoadingData && !filters.club_id"
                  :message="$t('app.domains.order.components.list.clubNotSelected')"/>
      <empty-list v-if="!isLoadingData && data.list.length === 0 && filters.club_id"
                  :message="$t('app.domains.order.components.list.emptyListMessage')"/>
      <template v-if="isLoadingData">
        <loading-content :placeholders="3"/>
      </template>
    </v-card>
  </div>
</template>
