import i18n from '../translation'

export default [
  {
    text: function () {
      return null
    },
    auth: false,
    children: [
      {
        route: 'home',
        icon: 'fas fa-table-tennis',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.main')
        },
        auth: false,
        hideLogged: false
      },
      {
        route: 'auth.login',
        icon: 'fas fa-user',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.authLogin')
        },
        auth: false,
        hideLogged: true
      },
      {
        route: 'user.register',
        icon: 'fas fa-user-plus',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.userRegister')
        },
        auth: false,
        hideLogged: true
      }
    ]
  },
  {
    text: function () {
      return i18n.t('components.main.sidebar.menu.sections.clubs')
    },
    auth: false,
    children: [
      {
        route: 'schedule.daily',
        icon: 'far fa-calendar-alt',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.scheduleDaily')
        },
        auth: false
      },
      {
        route: 'schedule.index',
        icon: 'fas fa-check-circle',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.scheduleIndex')
        },
        auth: true
      },
      {
        route: 'schedule.invites',
        icon: 'fas fa-users',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.scheduleInvites')
        },
        auth: true
      },
      {
        route: 'order.index',
        icon: 'fas fa-warehouse',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.orderIndex')
        },
        auth: true
      }
    ]
  },
  {
    text: function () {
      return i18n.t('components.main.sidebar.menu.sections.stages')
    },
    auth: false,
    children: [
      {
        route: 'registration.index',
        icon: 'fas fa-list',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.registrationIndex')
        },
        auth: true
      },
      {
        route: 'stage.list',
        icon: 'fas fa-trophy',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.stageList')
        },
        auth: false
      }
    ]
  },
  {
    text: function () {
      return i18n.t('components.main.sidebar.menu.sections.user')
    },
    auth: true,
    children: [
      {
        route: 'user.friends',
        icon: 'fas fa-user-friends',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.userFriends')
        },
        auth: true
      },
      {
        route: 'user.show',
        icon: 'fas fa-user-circle',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.userShow')
        },
        auth: true
      }
    ]
  },
  {
    text: function () {
      return 'Gripo'
    },
    auth: false,
    children: [
      {
        route: 'common.terms-conditions',
        icon: 'fas fa-file-alt',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.termsConditions')
        },
        auth: false
      },
      {
        route: 'common.privacy-policies',
        icon: 'fas fa-user-secret',
        text: function () {
          return i18n.t('components.main.sidebar.menu.items.privacyPolicies')
        },
        auth: false
      }
    ]
  }
]

