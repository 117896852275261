import Api from '../common/api/Api'

export default class UserService extends Api {
  constructor () {
    super({domain: 'user'})
  }

  async account () {
    return this.get({route: 'account'})
  }

  async register (data) {
    return this.post({route: 'register', data})
  }

  async checkActivationCode (data) {
    return this.post({route: 'check-activation-code', data})
  }

  async sendActivationCode (data) {
    return this.post({route: 'send-activation-code', data})
  }

  async recoveryAccess (data) {
    return this.post({route: 'recovery-access', data})
  }

  async updatePassword (data) {
    return this.put({route: 'update-password', data})
  }

  async searchUsers (data) {
    return this.post({route: 'search', data})
  }

  async changeAvatar (data) {
    return this.put({route: 'avatar', data, id: data.id})
  }

  async update (data) {
    return this.put({data})
  }

  async updateSubscription (data) {
    return this.put({route: 'push-subscription', data, id: data.id})
  }

  async getFriends (data, id) {
    return this.get({route: 'friends', data, id: id})
  }

  async storeFriend (data) {
    return this.post({route: 'friend', data})
  }

  async acceptFriend (id) {
    return this.put({route: 'accept-friend', id})
  }

  async denyFriend (id) {
    return this.put({route: 'deny-friend', id})
  }

  async notifications () {
    return this.get({route: 'notifications', cache: 'notifications'})
  }

  async updateNotification (id, data) {
    return this.put({route: 'notification', id, data})
  }

  async updateNotifications (data) {
    return this.post({route: 'notification/update', data})
  }

  async updateSettings (data) {
    return this.put({route: 'update-settings', data})
  }

  async sendActivationAccount (data) {
    return this.post({route: 'send-activation-account', data})
  }

  async confirmAccount (hash) {
    return this.get({route: `account/${hash}/confirm`})
  }

  async activateAccount (hash) {
    return this.get({route: `account/${hash}/activate`})
  }
}
